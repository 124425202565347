import React from 'react';

const MessagesIcon = () => {
    return (
        <svg width="22" height="25" viewBox="0 -2 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.325 13.1292C6.325 13.0333 6.325 12.9375 6.325 12.8417C6.325 12.5542 6.22917 12.3625 6.22917 12.075C6.22917 8.625 9.775 5.75 14.1833 5.75C14.4708 5.75 14.7583 5.75 15.0458 5.75C15.1417 5.75 15.2375 5.75 15.3333 5.75C15.525 5.75 15.7167 5.75 15.9083 5.84583C16.0042 5.84583 16.1958 5.94167 16.2917 5.94167C16.4833 5.94167 16.5792 6.0375 16.7708 6.0375C16.8667 6.0375 17.0583 6.13333 17.1542 6.13333C17.25 6.13333 17.3458 6.22917 17.5375 6.22917C17.6333 6.22917 17.7292 6.325 17.825 6.325C17.4417 2.77917 13.6083 0 8.9125 0C4.025 0.191667 0 3.25833 0 7.09167C0 9.00833 1.05417 10.925 2.97083 12.1708C3.1625 12.2667 3.25833 12.5542 3.1625 12.7458L2.20417 14.5667L4.69583 13.3208C4.79167 13.225 4.98333 13.225 5.07917 13.3208C5.55833 13.5125 6.0375 13.6083 6.51667 13.7042C6.51667 13.6083 6.51667 13.6083 6.51667 13.5125C6.42083 13.4167 6.325 13.225 6.325 13.1292ZM12.075 3.64167C12.5542 3.64167 12.8417 4.025 12.8417 4.40833C12.8417 4.79167 12.4583 5.27083 12.075 5.27083C11.5958 5.27083 11.3083 4.8875 11.3083 4.50417C11.3083 4.12083 11.5958 3.64167 12.075 3.64167ZM4.98333 4.50417C4.98333 4.025 5.36667 3.7375 5.75 3.7375C6.22917 3.7375 6.51667 4.12083 6.51667 4.50417C6.51667 4.8875 6.22917 5.27083 5.75 5.27083C5.36667 5.27083 4.98333 4.8875 4.98333 4.50417Z" fill="#09B83E"/>
            <path d="M21.0833 12.075C21.0833 10.2542 19.8375 8.62499 18.0167 7.66665C17.7292 7.57082 17.5375 7.37915 17.25 7.28332C17.1542 7.28332 17.0583 7.18749 17.0583 7.18749C16.7708 7.09165 16.4833 6.99582 16.1958 6.89999C15.9083 6.80415 15.6208 6.80415 15.3333 6.70832C15.2375 6.70832 15.1417 6.70832 15.1417 6.70832C14.8542 6.70832 14.5667 6.61249 14.1833 6.61249C10.35 6.61249 7.1875 9.00832 7.1875 11.9792C7.1875 12.1708 7.1875 12.3625 7.1875 12.5542C7.1875 12.65 7.1875 12.65 7.1875 12.7458C7.1875 12.8417 7.1875 13.0333 7.28333 13.1292C7.28333 13.225 7.28333 13.225 7.37917 13.3208C7.37917 13.4167 7.475 13.5125 7.475 13.6083C7.475 13.7042 7.57083 13.8 7.57083 13.8958C8.52917 16.0042 11.1167 17.4417 14.0875 17.4417C15.1417 17.4417 16.1958 17.25 17.1542 16.8667C17.25 16.8667 17.25 16.8667 17.3458 16.8667C17.4417 16.8667 17.5375 16.8667 17.5375 16.9625L19.2625 17.825L18.6875 16.675C18.5917 16.4833 18.6875 16.1958 18.8792 16.1C20.2208 15.0458 21.0833 13.6083 21.0833 12.075ZM11.6917 11.8833C11.2125 11.8833 10.925 11.5 10.925 11.1167C10.925 10.6375 11.3083 10.35 11.6917 10.35C12.1708 10.35 12.4583 10.7333 12.4583 11.1167C12.4583 11.5 12.1708 11.8833 11.6917 11.8833ZM16.5792 11.8833C16.1 11.8833 15.8125 11.5 15.8125 11.1167C15.8125 10.6375 16.1958 10.35 16.5792 10.35C17.0583 10.35 17.3458 10.7333 17.3458 11.1167C17.4417 11.5 17.0583 11.8833 16.5792 11.8833Z" fill="#09B83E"/>
        </svg>
    );
};

export default MessagesIcon;