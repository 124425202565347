import React from 'react';
import {ContentTag} from "./Content.style";

const Content = () => {
    return (
        <ContentTag>
            <div className="container">
                <h3>
                    Политика конфиденциальности
                </h3>
                <p>
                    Настоящая Политика конфиденциальности определяет, каким образом сайт cube80.com собирает, использует,
                    хранит и раскрывает информацию, полученную от пользователей на веб-сайте https://cube80.com
                    ("Сайт"). Данная политика конфиденциальности относится и к Сайту, всем поддоменам Сайта и всем
                    продуктам и услугам, предлагаемым Cube 80.
                </p>
                <p>
                    Эта страница содержит сведения о том, какую информацию мы или третьи лица могут получать, когда Вы
                    пользуетесь нашим Сайтом. Мы надеемся, что эти сведения помогут Вам принимать осознанные решения в
                    отношении предоставляемой нам информации о себе.
                </p>
                <p>
                    Настоящая Политика конфиденциальности распространяется непосредственно на этот Сайт и на информацию,
                    получаемую с его помощью. Она не распространяется ни на какие другие сайты и не применима к
                    веб-сайтам третьих лиц, которые могут содержать упоминание о нашем Сайте и с которых могут делаться
                    ссылки на Сайт, а также ссылки с этого Сайта на другие сайты сети Интернет.
                </p>
                <h6>
                    Получаемая информация
                </h6>
                <p>
                    Когда Вы посещаете Сайт, мы определяем IP адрес, имя домена с которого Вы к нам пришли (например,
                    "google.com") и страну регистрации данного ip , а так же фиксируем все переходы посетителей с одной
                    страницы Сайта на другую.
                </p>
                <p>
                    Сведения, которые мы получаем на Сайте, могут быть использованы для того, чтобы облегчить
                    пользование Сайтом. Сайт собирает только общую информацию, которую Ваш браузер предоставляет
                    добровольно при посещении Сайта.
                </p>
                <p>
                    Сайт применяет стандартную технологию "cookies" ("куки") для настройки стилей отображения Сайта под
                    параметры экрана монитора. "Куки" представляет собой данные с веб-сайта, который сохраняет на
                    жестком диске Вашего же компьютера. В "cookies" содержится информация, которая может быть
                    необходимой для настройки Сайта, - для сохранения Ваших установок вариантов просмотра и сбора
                    статистической информации по Сайту, т.е. какие страницы Вы посетили, что было загружено, имя домена
                    интернет-провайдера и страна посетителя, а также адреса сторонних веб-сайтов, с которых совершен
                    переход на Сайт и далее.
                </p>
                <p>
                    Также данную технологию использует установленные на Сайте счетчики компании Yandex/Rambler/Google и
                    т.п.
                </p>
                <p>
                    Технология "Cookies" не содержит никаких личных сведений относительно Вас. Чтобы просматривать
                    материал без "cookies", Вы можете настроить свой браузер таким образом, чтобы она не принимала
                    "cookies", либо уведомляла Вас об их посылке (настройки браузеров различны, поэтому советуем Вам
                    получить справку в разделе "Помощь" и выяснить как изменить установки браузера по "cookies").
                    Подробно о работе куки файлов Вы можете прочитать здесь: http://ru.wikipedia.org/wiki/HTTP_cookie
                </p>
                <p>
                    Кроме того, Сайт использует стандартные возможности (журналы) веб-сервера для подсчета количества
                    посетителей и оценки технических возможностей хост-сервера, рейтинги и счетчики посещаемости от
                    сторонних организаций. Мы используем эту 
                    информацию для того, чтобы определить сколько человек посещает Сайт и расположить страницы наиболее
                    удобным для пользователей способом, обеспечить соответствие Сайта с используемыми Вами браузерам, и
                    сделать содержание Сайта максимально полезным для посетителей. Мы записываем сведения по
                    перемещениям на Сайте, но не об отдельных посетителях Сайта, так что никакая конкретная информация
                    относительно Вас лично не будет сохраняться или использоваться Администрацией Сайта без Вашего
                    согласия.
                </p>
                <p>
                    Также мы можем собирать личную идентификационную информацию от пользователей, когда пользователь
                    посещает наш Сайт, регистрируется на Сайте, оформляет заказ, заполняет формы и в связи с другой
                    активностью на Сайте. Пользователя могут попросить при необходимости указывать имя, электронный
                    адрес, номер телефона, данные кредитной карты. Пользователи могут, однако, посещать наш Сайт
                    анонимно. Мы собираем личную идентификационную информацию пользователей, только если они добровольно
                    предоставляют нам такую информацию. Пользователи всегда могут отказаться в предоставлении личной
                    идентификационной информации, за исключением случаев, когда это может помешать пользоваться
                    отдельными функциями Сайта.
                </p>
                <h6>
                    Как мы используем собранную информацию
                </h6>
                <p>
                    Cube 80 может собирать и использовать личную информацию пользователей для следующих целей:
                </p>
                <p>
                    - Для улучшения обслуживания клиентов. Предоставляемая вами информация помогает нам реагировать на
                    запросы клиентов более эффективно;
                </p>
                <p>
                    - Чтобы персонализировать пользовательский опыт. Мы можем использовать информацию для определения
                    кто из посетителей Сайта наиболее заинтересован в услугах и ресурсах предоставляемых на нашем Сайте;
                </p>
                <p>
                    - Для улучшения нашего Сайта. Мы можем использовать обратную связь, которую Вы предоставляете, чтобы
                    улучшить наши продукты и услуги;
                </p>
                <p>
                    - Для обработки платежей. Мы можем использовать информацию о пользователях при оформлении заказа для
                    оформления платежей и только для этого. Мы не делимся этой информацией с третьими лицами, за
                    исключением тех случаев, когда необходимо для предоставления услуг;
                </p>
                <p>
                    - Чтобы отправлять пользователям информацию, которую они согласились получать на темы, которые, как
                    мы думаем, будут представлять для них интерес;
                </p>
                <p>
                    - Чтобы отправить периодические сообщения электронной почты, которые могут включать новости
                    компании, обновления, информацию о продуктах и услугах и т.д.
                </p>
                <p>
                    Если пользователь хотел бы отказаться от получения последующих писем, мы включаем подробное описание
                    инструкции по тому, как отписаться в нижней части каждой электронной почты или пользователь может
                    связаться с нами через наш Сайт.
                </p>
                <h6>
                    Персональные данные Пользователя
                </h6>
                <p>
                    Вводя личные данные в какой-либо форме обратной связи на Сайте, нажимая кнопку «Отправить»,
                    Пользователь принимает решение о предоставлении своих персональных данных и дает согласие на их
                    обработку своей волей и в своем интересе, при этом такое согласие Пользователя является конкретным и
                    сознательным.
                </p>
                <p>
                    Персональные данные, полученные от Пользователя, являющегося субъектом персональных данных,
                    используются администрацией сайта исключительно в целях заключения договоров с Пользователем или для
                    исполнения уже заключенного договора. В отношении персональной информации Пользователя сохраняется
                    ее конфиденциальность, ни при каких обстоятельствах она не распространяется и не предоставляется
                    третьим лицам без согласия Пользователя, за исключением случаев и прямо предусмотренных действующим
                    законодательством Российской Федерации.
                </p>
                <p>
                    Администрация Сайта не проверяет достоверность персональной информации, предоставляемой
                    Пользователями, и не осуществляет проверку их дееспособности. При этом Администрация Сайта исходит
                    из того, что информация, переданная им от пользователей, является достоверной.
                </p>
                <p>
                    Пользователь несет ответственность за предоставление персональных данных третьего лица.
                </p>
                <p>
                    Личные данные, вводимые Пользователем при заполнении формы обратной связи, заявки на разработку
                    сайта, на обратный звонок, могут быть использованы Администрацией Сайта для подготовки и направления
                    оферты, совершения сделки, выполнения услуги или улучшения качества обслуживания.
                </p>
                <p>
                    Пользователь соглашается с тем, что Администрация Сайта вправе использовать персональные данные
                    Пользователя для осуществления электронной новостной или рекламной рассылки, в том числе посредством
                    email-писем, мессенджеров Telegram, Whatsapp, а также сайта vk.com и SMS-сообщений.
                </p>
                <h6>
                    Обработка персональных данных Пользователей
                </h6>
                <p>
                    Принятие условий настоящей Политики в части положений об обработке персональных данных,
                    осуществляется путем проставления Пользователем соответствующей отметки при заполнении любой формы
                    обратной связи и является выраженным осознанным согласием Пользователя на обработку персональных
                    данных.
                </p>
                <p>
                    Пользователь соглашается с тем, что Администрация Сайта имеет право на хранение и обработку, в том
                    числе и автоматизированную, любой информации, относящейся к персональным данным Пользователя в
                    соответствии с международным законодательством в сфере обработки информации, включая сбор,
                    систематизацию, накопление, хранение, уточнение, использование, распространение (в том числе
                    передачу), обезличивание, блокирование, уничтожение персональных данных, предоставленных
                    Пользователем при заполнении формы обратной связи на Сайте.
                </p>
                <p>
                    Согласие на обработку персональных данных может быть в любой момент отозвано Пользователем путем
                    письменного обращения в адрес Администрации Сайта. В случае отзыва Пользователем согласия на
                    обработку персональных данных, Администрация Сайта удаляет персональные данные Пользователя и не
                    вправе использовать их в будущем.

                </p>
                <h6>
                    Как мы защищаем вашу информацию
                </h6>
                <p>
                    Администрацией Сайта обеспечивается конфиденциальность и безопасность при обработке персональных
                    данных. Мы принимаем соответствующие меры безопасности по сбору, хранению и обработке любых
                    собранных данных для защиты их от несанкционированного доступа, изменения, раскрытия или уничтожения
                    Вашей личной информации (имя пользователя, пароль, информация транзакции и данные, хранящиеся на
                    нашем Сайте).
                </p>
                <h6>
                    Общий доступ к личной информации

                </h6>
                <p>
                    Мы не продаем, не обмениваем или не даем в аренду личную информацию пользователей. Мы можем
                    предоставлять общие агрегированные демографические данные, не связанные с личной информацией, нашими
                    партнерами и рекламодателями для целей, описанных выше. Мы можем использовать сторонних поставщиков
                    услуг, чтобы помочь нам управлять нашим бизнесом и Сайтом или управлять деятельностью от нашего
                    имени, например, проведение рассылки или статистические и иные исследования. Мы можем делиться этой
                    информацией с этими третьими лицами для ограниченных целей при условии, что Вы дали нам
                    соответствующие разрешения.

                </p>
                <h6>
                    Изменения в политике конфиденциальности
                </h6>
                <p>
                    Ozzo Studio имеет право по своему усмотрению обновлять данную политику конфиденциальности в любое
                    время. В этом случае мы опубликуем уведомление на главной странице нашего Сайта и сообщим Вам об
                    этом по электронной почте. Мы рекомендуем пользователям регулярно проверять эту страницу для того,
                    чтобы быть в курсе любых изменений о том, как мы защищаем личную информацию, которую мы собираем.
                    Используя Сайт, Вы соглашаетесь с принятием на себя ответственности за периодическое ознакомление с
                    Политикой конфиденциальности и изменениями в ней.
                </p>
                <h6>
                    Ваше согласие с этими условиями
                </h6>
                <p>
                    Используя этот Сайт, Вы выражаете свое конкретное и осознанное согласие с этой Политикой. Если Вы не
                    согласны с этой политикой, пожалуйста, не используйте наш Сайт. Ваше дальнейшее использование Сайта
                    после внесения изменений в настоящую политику будет рассматриваться как Ваше согласие с этими
                    изменениями.
                </p>
                <h6>
                    Отказ от ответственности
                </h6>
                <p>
                    Помните, политика конфиденциальности при посещении сторонних Сайтов третьих лиц, не подпадает под
                    действия данного документа. Администрация Сайта не несет ответственности за действия других
                    веб-сайтов.
                </p>
            </div>
        </ContentTag>
    );
};

export default Content;