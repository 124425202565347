import React from 'react';

const VkIcon = () => {
    return (
        <svg width="25" height="14" viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.5403 0.610759H19.5643C19.402 0.610758 19.2428 0.654756 19.1035 0.738071C18.9642 0.821385 18.8502 0.940899 18.7734 1.08389C18.7734 1.08389 17.1876 4.00435 16.6783 4.98802C15.3079 7.6342 14.4206 6.80347 14.4206 5.57535V1.3408C14.4206 0.986944 14.28 0.647584 14.0298 0.397372C13.7796 0.14716 13.4402 0.00659233 13.0864 0.00659233H10.097C9.68984 -0.0236768 9.28175 0.0506329 8.91144 0.222464C8.54113 0.394295 8.2209 0.657942 7.98116 0.988363C7.98116 0.988363 9.49914 0.742927 9.49914 2.78827C9.49914 3.29546 9.52526 4.75451 9.54907 5.97827C9.55356 6.15861 9.5026 6.33598 9.40309 6.48644C9.30357 6.6369 9.16028 6.75319 8.99257 6.81963C8.82486 6.88607 8.6408 6.89946 8.46525 6.85797C8.28969 6.81648 8.13109 6.72213 8.01086 6.58765C6.78725 4.88581 5.77368 3.04241 4.9922 1.09748C4.92509 0.951984 4.81765 0.828796 4.68263 0.742526C4.54761 0.656257 4.39068 0.610526 4.23046 0.610759C3.36819 0.610759 1.64271 0.610759 0.61801 0.610759C0.518644 0.610083 0.4206 0.63354 0.332299 0.679115C0.243999 0.72469 0.168083 0.791021 0.111073 0.872409C0.0540633 0.953796 0.0176643 1.04781 0.00500325 1.14637C-0.00765785 1.24492 0.00379745 1.34508 0.038385 1.43824C1.1353 4.44706 5.86045 13.9024 11.2537 13.9024H13.5238C13.7616 13.9024 13.9897 13.8079 14.1579 13.6398C14.3261 13.4716 14.4206 13.2435 14.4206 13.0056V11.6349C14.4206 11.4629 14.4709 11.2947 14.5653 11.151C14.6597 11.0073 14.7941 10.8944 14.9519 10.8261C15.1098 10.7578 15.2841 10.7372 15.4535 10.7668C15.6229 10.7964 15.7799 10.8749 15.9052 10.9927L18.6223 13.5455C18.8663 13.7748 19.1885 13.9024 19.5234 13.9024H23.0913C24.8122 13.9024 24.8122 12.7083 23.874 11.7843C23.2137 11.134 20.831 8.62253 20.831 8.62253C20.6269 8.41115 20.5052 8.13375 20.4879 7.84047C20.4705 7.54719 20.5586 7.25737 20.7363 7.02338C21.5064 6.00988 22.7661 4.35085 23.3004 3.64013C24.0306 2.66871 25.3522 0.610759 23.5403 0.610759Z" fill="#597DA3"/>
        </svg>
    );
};

export default VkIcon;