import React from 'react';

const WhatsAppIcon = () => {
    return (
        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.48478 4.3176C6.31027 3.92288 6.17794 3.90787 5.91356 3.89778C5.82356 3.89287 5.7233 3.88795 5.61206 3.88795C5.26809 3.88795 4.90852 3.98291 4.69153 4.19276C4.42716 4.44763 3.77114 5.04223 3.77114 6.26145C3.77114 7.4808 4.71263 8.66004 4.83961 8.82512C4.97194 8.98981 6.67519 11.5284 9.31992 12.563C11.3881 13.3725 12.0018 13.2975 12.4725 13.2026C13.1601 13.0628 14.0223 12.5828 14.2393 12.0033C14.4561 11.4236 14.4561 10.9289 14.3925 10.824C14.3291 10.719 14.1545 10.6593 13.8901 10.5342C13.6257 10.4092 12.3403 9.80953 12.097 9.72958C11.8589 9.64471 11.6315 9.67473 11.4518 9.91459C11.1978 10.2494 10.9493 10.5893 10.7482 10.7941C10.5896 10.954 10.3303 10.974 10.1135 10.889C9.82252 10.7743 9.00802 10.5041 8.00297 9.65972C7.22531 9.00522 6.69642 8.19081 6.54314 7.94591C6.38972 7.69609 6.52725 7.55105 6.64889 7.41625C6.78122 7.26126 6.90806 7.15142 7.04025 7.00639C7.17258 6.86149 7.24655 6.78659 7.3312 6.61659C7.4212 6.45177 7.35764 6.28177 7.29422 6.15679C7.23066 6.03155 6.70177 4.8122 6.48478 4.3176ZM8.99733 0C4.0358 0 0 3.81265 0 8.49987C0 10.3587 0.634781 12.0828 1.7138 13.4819L0.592312 16.6401L4.05169 15.5959C5.47453 16.4854 7.17244 17 9.00267 17C13.9642 17 18 13.1871 18 8.50013C18 3.81291 13.9642 0.000265625 9.00267 0.000265625H8.99747V0H8.99733Z" fill="#43D854"/>
        </svg>
    );
};

export default WhatsAppIcon;